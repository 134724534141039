<template>
  <Portlet
    :title="$t('commentCount')"
    icon="chart-area"
  >
    <div class="historyCountChart">
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <div class="m-form__group form-group">
          <div class="m-radio-inline">
            <label class="m-radio">
              <input
                v-model="days"
                type="radio"
                :name="'mediaReportChartRadios'"
                value="182"
              ><sup>1</sup>/<sub>2</sub> a {{ $t('year') }}
              <span />
            </label>
            <label class="m-radio">
              <input
                v-model="days"
                type="radio"
                :name="'mediaReportChartRadios'"
                value="30"
              >30 {{ $t('days') }}
              <span />
            </label>
            <label class="m-radio">
              <input
                v-model="days"
                type="radio"
                :name="'mediaReportChartRadios'"
                value="7"
              >7 {{ $t('days') }}
              <span />
            </label>
          </div>
        </div>

        <template v-if="report != null">
          <kendo-chart
            ref="chart"
            :category-axis-categories="chartDates"
            :legend-position="'top'"
            :chart-area-background="''"
            :series-defaults-type="'column'"
            :series-defaults-stack="true"
            :series-defaults-style="'smooth'"
            :value-axis-labels-format="'{0}'"
            :value-axis-line-visible="false"
            :value-axis-axis-crossing-value="'-10'"
            :category-axis-major-grid-lines-visible="false"
            :category-axis-labels-rotation="'auto'"
            :tooltip-visible="true"
            :tooltip-format="'{0}'"
            :tooltip-template="tooltiptemplate"
            :theme="'sass'"
          >
            <kendo-chart-series-item
              v-for="(rep, index) in report.reports"
              :key="`rep-${ index }`"
              :name="rep.type"
              :data="rep.countOnDays"
            />
          </kendo-chart>
        </template>
        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>
      </template>
    </div>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { responsiveKendoGridMixin } from '@/mixins/responsiveKendoGridMixin.js';

export default {
  name: 'HistoryCountChart',
  mixins: [
    dateTimeMixin,
    responsiveKendoGridMixin
  ],
  data () {
    return {
      days: 7,
      chartDates: null,
      report: null,
      loading: false,
      tooltiptemplate: '#= category #, #= series.name #: #= value #',
    }
  },
  watch: {
    days () {
      this.getData();
    }
  },
  created () {
    this.getData();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      this.responsiveKendoGridMixin_refresh(this.$refs.chart);
    },
    getData () {
      this.loading = true;
      this.axios.get(`/Installation/GetInstallationCommentReportByType?days=${ this.days }`)
        .then((response) => {
          if(response && response.data != null) {
            this.report = response.data;
            this.chartDates = this.report.dates.map(o => this.$options.filters.dateTime_date(o));
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
</script>
